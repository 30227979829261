import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import lightenDarkenColor from "../utils/utils";

const StyledButton = styled.button`
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkblue};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${lightenDarkenColor(
      "#142341",
      20
    )}; // Lighter shade of darkblue for hover
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.colors.darkblue}; // Keeping dark blue for active state
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background-color: ${({ theme }) =>
      theme.colors.inputBackground}; // Light background for disabled state
    color: ${({ theme }) =>
      theme.colors.background}; // Dark text for disabled state
    cursor: not-allowed;
  }
`;

function Button({ children, onClick, disabled, ...rest }) {
  return (
    <StyledButton onClick={onClick} disabled={disabled} {...rest}>
      {children}
    </StyledButton>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export default Button;
