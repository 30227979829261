import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "styled-components";
import * as Sentry from "@sentry/react";
import { Dedupe as DedupeIntegration } from "@sentry/integrations";
import "./index.css";
import axios from "axios";
import Main from "./views/main";
import App from "./App";
import Error from "./components/error";
import FatalError from "./errors/fatal-error";
import myTheme from "./myTheme";

// In order to test you can use 123approved or 123denied as token
// approve => http://localhost:3020/?action=approve&token=123approved
// deny => http://localhost:3020/?action=deny&token=123denied

// Dealing with languages
const labels = {
  fr: {
    NO_PARAMS_TITLE: "Oops !",
    NO_PARAMS_MESSAGE:
      "Afin d'accéder à l'application d'approbation, vous devez cliquez sur l'un des liens reçus par email.",
    INIT_FAILED_TITLE: "Oops, quelque chose s'est mal passé !",
    INIT_FAILED_MESSAGE:
      "Notre support technique a été averti. Vous pourrez recharger la page dans quelques minutes.",
    TRAVEL_APPROVED_MESSAGE:
      "Le voyage a été accepté, l'utilisateur a été notifié.",
    TRAVEL_DENIED_MESSAGE:
      "Le voyage a bien été refusé, l'utilisateur a été notifié de votre refus !",
    REFUSAL_REASON_LABEL: "Motif du refus (optionnel)",
    REFUSAL_REASON_PLACEHOLDER: "Vous pouvez ajouter un motif de refus ici",
    REFUSE_BUTTON: "Refuser",
    REQUEST_EXPIRED:
      "Demande expirée, merci de bien vouloir renouveler les billets",
    PAGE_WILL_CLOSE: "La page se fermera dans",
    SENDING_ANSWER: "Chargement en cours...",
    COMPLIANT: "Conforme",
    NOT_COMPLIANT: "Non conforme",
    TRAVELERS: "Voyageurs: ",
    ACCEPT: "Accepter",
    REFUSE: "Refuser",
    CONFIRM_INFO:
      "Vous vous apprêtez à accepter la demande d'approbation suivante, souhaitez-vous continuer ?",
    DENY_INFO:
      "Vous vous apprêtez à refuser la demande d'approbation suivante, souhaitez-vous continuer ?",
  },
  en: {
    NO_PARAMS_TITLE: "Oops !",
    NO_PARAMS_MESSAGE:
      "In order to properly access the approval application, you should follow the link you received by email.",
    INIT_FAILED_TITLE: "Oops, something went wrong !",
    INIT_FAILED_MESSAGE:
      "Our technical support has been warned. Try again in few minutes.",
    TRAVEL_APPROVED_MESSAGE:
      "Travel has been approved and the user has been notified.",
    TRAVEL_DENIED_MESSAGE:
      "Travel has been denied, the traveler has been notified !",
    REFUSAL_REASON_LABEL: "Refusal reason (optional)",
    REFUSAL_REASON_PLACEHOLDER: "You can add a refusal reason here",
    REFUSE_BUTTON: "Denny",
    REQUEST_EXPIRED: "Request expired, please renew the tickets",
    PAGE_WILL_CLOSE: "Page will close in",
    SENDING_ANSWER: "Loading...",
    COMPLIANT: "Compliant",
    NOT_COMPLIANT: "Not compliant",
    TRAVELERS: "Travelers: ",
    ACCEPT: "Accept",
    REFUSE: "Refuse",
    CONFIRM_INFO:
      "You are about to accept the following approval request, do you want to continue?",
    DENY_INFO:
      "You are about to deny the following approval request, do you want to continue?",
  },
};

window.i18 = (language, labelID) => labels[language][labelID];

// Init Sentry. We do not want to capture exception automatically. We will send them manually
Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: "https://db55edaad5ec409c960e3769652d2066@o526021.ingest.sentry.io/5641001",
  release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [...Sentry.defaultIntegrations, new DedupeIntegration()],
  defaultIntegrations: false,
});

const container = document.getElementById("root");
const root = createRoot(container);

// Init react app and render it
const renderApprovalApp = (configuration, action, token) => {
  root.render(
    <ThemeProvider theme={myTheme}>
      <App action={action} token={token} configuration={configuration} />
    </ThemeProvider>
  );
};

const renderErrorApp = (lang, title, message) => {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={myTheme}>
        <Main>
          <Error id="init-error">
            <div>{window.i18(navigator.language.split("-")[0], title)}</div>
            <div>{window.i18(navigator.language.split("-")[0], message)}</div>
          </Error>
        </Main>
      </ThemeProvider>
    </React.StrictMode>
  );
};

// Loading configuration file
const loadConfigurationFile = async (action, token) => {
  try {
    const configuration = await axios.get(`${process.env.REACT_APP_CONFIG}`);
    renderApprovalApp(configuration.data.api, action, token);
  } catch (error) {
    renderErrorApp(
      navigator.language.split("-"),
      "INIT_FAILED_TITLE",
      "INIT_FAILED_MESSAGE"
    );
    Sentry.captureException(
      new FatalError(`Unable to load config file on approval module!`),
      {
        level: "fatal",
      }
    );
  }
};

// Catch url params
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const token = urlParams.get("token");
const action = urlParams.get(`action`);

// If params are there we render approval app.
if (token && action) {
  loadConfigurationFile(action, token);
  // If not, we render an error app
} else {
  renderErrorApp(
    navigator.language.split("-"),
    "NO_PARAMS_TITLE",
    "NO_PARAMS_MESSAGE"
  );
}
