const myTheme = {
  colors: {
    background: "#E6EAEF",
    inputBackground: "#FFFFFF",
    darkblue: "#142341",
    green: "#AFDC00",
    red: "#A5506E",
    white: "#fff",
  },
};

export default myTheme;
