import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ReactComponent as Rail } from "../assets/img/icons/rail.svg";
import { ReactComponent as Flight } from "../assets/img/icons/flight.svg";
import { ReactComponent as Hotel } from "../assets/img/icons/hotel.svg";
import { ReactComponent as CarRental } from "../assets/img/icons/car_rental.svg";
import { ReactComponent as User } from "../assets/img/icons/user.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.darkblue};
  box-sizing: border-box;
  gap: 1em;
`;

const Ticket = styled.div`
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid lightgrey;
  padding: 1em;
  border-radius: 4px;
`;

const TransportIcon = styled.div`
  svg {
    width: 2em;
    height: 2em;
  }
`;

const Route = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    margin-left: 1em;
  }
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
`;

const TravelPaxs = styled.div`
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  font-weight: 200;

  svg {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;

    path {
      fill: ${({ theme }) => theme.colors.darkblue};
    }
  }
`;

const Price = styled.span`
  margin-right: 1em;
`;

const Compliance = styled.span`
  border-radius: 4px;
  display: inline-block;
  padding: 0.25em;
  background: ${({ theme, compliant }) =>
    compliant ? theme.colors.green : theme.colors.red};
  color: white;
`;

function Travel({ travelData }) {
  return (
    <Container data-testid="travel">
      {travelData.travel.map((travel) => (
        <Ticket key={`travel-${travel.index}`}>
          <Route>
            <TransportIcon>
              {travel.type === "rail" && <Rail />}
              {travel.type === "flight" && <Flight />}
              {travel.type === "hotel" && <Hotel />}
              {travel.type === "car_rental" && <CarRental />}
            </TransportIcon>
            <p>{travel.routes.join(" - ")}</p>
          </Route>
          <Details>
            <TravelPaxs>
              <User />
              {travel.paxs.join(", ")}
            </TravelPaxs>
            <div>
              <Price>{travel.amount / 100}€</Price>
              {travel.compliant === true && (
                <Compliance compliant="true">
                  {window.i18(navigator.language.split("-")[0], "COMPLIANT")}
                </Compliance>
              )}
              {travel.compliant === false && (
                <Compliance>
                  {window.i18(
                    navigator.language.split("-")[0],
                    "NOT_COMPLIANT"
                  )}
                </Compliance>
              )}
            </div>
          </Details>
        </Ticket>
      ))}
    </Container>
  );
}

Travel.propTypes = {
  /** React node to be rendered within Button */
  travelData: PropTypes.shape({
    travel: PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.number,
        type: PropTypes.string,
        compliant: PropTypes.bool,
        amount: PropTypes.number,
        paxs: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }).isRequired,
};

export default Travel;
