import * as Sentry from "@sentry/react";
import FatalError from "./fatal-error";

// Formatting error
const formattingError = (error, defaultMessage) => {
  if (error.response) {
    return {
      title: window.i18(navigator.language.split("-")[0], "INIT_FAILED_TITLE"),
      message: error?.response?.data?.errorMessage,
    };
  }
  Sentry.captureException(
    new FatalError(`Something went wrong when calling ${defaultMessage} !`),
    {
      level: "fatal",
    }
  );
  return {
    title: window.i18(navigator.language.split("-")[0], "INIT_FAILED_TITLE"),
    message: window.i18(
      navigator.language.split("-")[0],
      "INIT_FAILED_MESSAGE"
    ),
  };
};

export default formattingError;
