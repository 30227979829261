import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkblue};
  font-weight: 500;
`;

const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  border: ${({ theme }) => `1px solid ${theme.colors.darkblue}`};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  padding: 0 1em;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.darkblue};
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition:
    border-color 0.3s,
    box-shadow 0.3s;

  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.lightgray};
  }
`;

function Input({ label, placeholder, value, onChange, ...props }) {
  return (
    <>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput
        {...props}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </>
  );
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Input.defaultProps = {
  placeholder: "",
};

export default Input;
