import React from "react";
import styled, { keyframes } from "styled-components";
import Container from "./container";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid ${({ theme }) => theme.colors.darkblue};
  border-right: 2px solid ${({ theme }) => theme.colors.darkblue};
  border-bottom: 2px solid ${({ theme }) => theme.colors.darkblue};
  border-left: 4px solid ${({ theme }) => theme.colors.background};
  background: transparent;
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const LoaderText = styled.p`
  color: ${({ theme }) => theme.colors.darkblue};
  margin: 0;
`;

function Loader() {
  return (
    <Container height="140px">
      <Spinner />
      <LoaderText>
        {window.i18(navigator.language.split("-")[0], "SENDING_ANSWER")}
      </LoaderText>
    </Container>
  );
}

export default Loader;
