import styled from "styled-components";

const Container = styled.div`
  display: flex;
  height: ${({ height }) => (height ? `${height}` : "")};
  width: ${({ width }) => (width ? `${width}` : "")};
  gap: 0.5em;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export default Container;
